<template>
  <div class="container-logout">
    <div class="logout-btn">
      <router-link
        to="/minha-conta"
        :class="{ is_ambassador: !!$store.state.user.user.is_ambassador }"
      >
        <img :src="foto_usuario" class="foto-usuario" :class="{ contains: foto_usuario }" />
        <img
          v-if="!!$store.state.user.user.is_ambassador"
          src="@/assets/img/icons/star_icon.svg"
          alt="star icon"
          width="35px"
          height="35px"
          class="star"
        />
      </router-link>

      <div class="space-flex">
        <div class="infos-user">
          <p>{{ userName }}</p>
        </div>

        <a @click="menuConfig">
          <img src="@/assets/img/icons/config.svg" class="item-config" />
        </a>

        <ul v-show="sub_menu" id="sub_config" class="sub-menu" :class="{'heaven': isHeaven}">
          <li @click="sub_menu = false">
            <!-- My Account -->
            <router-link to="/minha-conta" v-if="!$store.getters.setPanel">
              <img v-if="!isHeaven" src="@/assets/img/icons/user-config.svg" />
              <img v-else src="@/assets/img/icons-heaven/user-config.svg" />
              {{ $t("side.text_1949") }}
            </router-link>
            <!-- System Config -->
            <router-link to="/configuracoes" v-if="!$store.getters.setPanel">
              <img v-if="!isHeaven" src="@/assets/img/icons/company-config.svg" />
              <img v-else src="@/assets/img/icons-heaven/company-config.svg" />
              {{ $t("side.text_1951") }}
            </router-link>
            <!-- Sign Out -->
            <a href="#" id="logout-btn" @click.prevent="logout"
              ><img src="@/assets/img/icons/sign-out.svg" />
              {{ $t("side.text_1950") }}</a
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      name_user: "",
      sub_menu: false,
      item: null,
      top: 0,
      bottom: 0,
      fimMenu: 300,
      fimSubmenu: 0,
    };
  },
  computed: {
    foto_usuario() {
      if (this.$store.getters.getProfile) {
        return this.$store.getters.getProfile.image;
      } else return null;
    },
    userName() {
      if (this.$store.getters.getProfile) {
        if (this.$store.getters.getProfile.name) {
          const name = this.$store.getters.getProfile.name.split(" ");
          if (name.length > 1) {
            return `${name[0]} ${name[1]}`;
          } else {
            return name[0];
          }
        } else return "";
      } else return "";
    },
    isHeaven() {
      return this.$store.getters["getIsHeaven"]
    }
  },
  methods: {
    logout() {
      this.$store.dispatch("logoutRequest").catch((err) => {});
    },
    minha_conta() {
      this.$router.push("/minha-conta");
    },
    menuConfig(e) {
      this.item = e.currentTarget.parentElement.parentElement.parentElement;
      this.top = this.item.offsetTop - 120;
      if (this.$store.getters.setPanel) {
        this.top = this.item.offsetTop - 20;
      }
      const submenu = document.querySelector("#sub_config");
      submenu.style = `top: ${this.top}px`;
      this.bottom = this.top + submenu.offsetHeight;
      this.fimSubmenu = this.fimMenu + submenu.offsetLeft + 9;
      this.sub_menu = !this.sub_menu;
    },
    eventMouse() {
      window.addEventListener("mousemove", (e) => {
        if (
          e.clientX > this.fimSubmenu ||
          (e.clientX > this.fimMenu && e.clientY < this.top) ||
          (e.clientX > this.fimMenu && e.clientY > this.bottom)
        ) {
          this.sub_menu = false;
        }
      });
    },
  },
  mounted() {
    this.eventMouse();
  }
}
</script>

<style lang="scss" scoped>
.container-logout {
  position: absolute;
  bottom: 20px;
  padding-top: 20px;
}
.container-logout::before {
  content: "";
  width: 300px;
  height: 1px;
  background: #ededf0;
  display: block;
  margin-left: -40px;
  margin-bottom: 20px;
}

// novo rodape
.logout-btn {
  width: 100%;
  display: flex;
  margin-left: -8px;
}
.logout-btn a:not(.is_ambassador) .foto-usuario {
  margin-right: 20px;
}
.foto-usuario {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background: #ededf0;
  object-fit: contain;
}
.foto-usuario.contains {
  background: none;
}
.space-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.infos-user {
  display: block;
  margin-right: 25px;
  width: 110px;
}
.infos-user p {
  font-weight: 600;
  font-size: 14px;
  margin: 0;
}
.infos-user span {
  font-size: 12px;
}
.space-flex img.item-config {
  filter: invert(50%);
  width: 20px;
  cursor: pointer;
}
.sub-menu a {
  position: relative;
  display: flex;
  align-items: center;
  min-width: 200px;
  padding: 10px;
  padding-left: 0;
  cursor: pointer;
  color: #333;
  font-weight: 600;
  text-decoration: none;
  transition: all 0.3s ease;
  font-size: 15px;
  color: transparent;
  margin: 0 auto;
}
.sub-menu a img {
  filter: invert(50%);
  transition: all 0.3s ease;
  margin: 0;
  margin-right: 35px;
}
.sub-menu a:hover img {
  filter: invert(0);
}
img.arrow {
  /* margin: 0 !important; */
  margin-left: 10px !important;
}

/* Sub menu */
.sub-menu {
  width: 230px;
  box-shadow: 2px 6px 10px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  background: #fff;
  padding: 25px;
  position: fixed;
  left: 270px;
  z-index: 55555 !important;
  animation: showLeft 0.3s forwards;
}
#sub_config {
  width: 310px;
}
.sub-menu li a {
  font-weight: 600;
  color: #333;
  font-size: 14px;
  padding: 10px;
}
.sub-menu li a:hover {
  text-decoration: none;
  color: $base-color;
}
.sub-menu.heaven li a:hover {
  color: $base-color-heaven;
}
.sub-menu li a.router-link-exact-active::before {
  left: -15px;
}
.sub-menu li a + a {
  margin-top: 20px;
}
.sub-menu li a img {
  margin-right: 25px;
}
#logout-btn:hover {
  color: #ff0c37;
}
.is_ambassador {
  display: flex;
  margin-right: 20px;

  .star {
    position: relative;
    top: -15px;
    left: -10px;
  }
  img.foto-usuario {
    border: 2px solid #FF9E44;
    object-fit: cover;
    margin: 0;
  }
}
</style>
