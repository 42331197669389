<template>
<div class="css-variables">
  <modal-group-accept></modal-group-accept>
  <div fluid class="p-0" v-if="isAuthenticated">
    <b-row id="app" class="full-height p-0 m-0">
      <min-sidebar v-if="isMobile && sidebar && $route.name !== 'Seller-Register'" />

      <maxSidebar v-if="!isMobile && sidebar && $route.name !== 'Seller-Register'" />

      <main class="main" :class="{ mobile: isMobile }">
        <transition mode="out-in">
          <router-view />
        </transition>
      </main>
    </b-row>
  </div>
  <transition mode="out-in" v-else>
    <router-view></router-view>
  </transition>
</div>
</template>

<script>
import { EventBus } from "@/main.js";
// Seller
import maxSidebar from "@/components/Side/index";
import minSidebar from "@/components/Side/Toggle-sidebar/index";

export default {
  name: "app",
  components: {
    maxSidebar,
    minSidebar,
  },
  data() {
    return {
      client: {
        width: 0,
      },
      sidebar: false,
    };
  },
  computed: {
    active() {
      return this.$route.name;
    },
    getSidebarState() {
      return this.$store.getters["sidebarState"];
    },
    getWindowWidth() {
      return this.client.width > 900;
    },
    isMobile() {
      return this.client.width <= 768;
    },
    isAuthenticated() {
      return (
        this.$route.name != "AdminLogin" && this.$store.getters.isAuthenticated
      );
    },
    isWebView() {
      return window.isWebView == true;
    },
  },
  created() {
    // desbloqueando rotação
    if (this.detectOs() == "Android") {
      window.screen.orientation.lock("portrait");
    }

    //
    window.addEventListener("resize", this.handleResize);
    this.handleResize();

    EventBus.$on("errorNotify", (data) => {
      this.$bvToast.toast(data, {
        title: this.$t('app.warning'),
        variant: "danger",
        autoHideDelay: 5000,
        appendToast: true,
      });
    });

    // notificação perfil incompleto
    EventBus.$on("incomplete", () => {
      this.$bvToast.toast(
        this.$t('app.text_before_start_verification'),
        {
          title: this.$t('app.profile_data'),
          variant: "info",
          autoHideDelay: 8000,
          appendToast: true,
        }
      )
    })

    // Set language
    // let locale = localStorage.locationadm;
    // if(localStorage.user) {
    //   locale = JSON.parse(localStorage.user).country.country_code
    // }
    // this.selectCountry(locale);

    // Validar Domínio Greenn/Heaven
    const url = window.location.href
    if(url.includes(process.env.VUE_ADM_HEAVEN_PAGE)) {
      this.selectCountry('US');
      this.$store.dispatch('updateIsHeaven', true)
    } else {
      this.selectCountry('BR');
      this.$store.dispatch('updateIsHeaven', false)
    }

    // Validar se é cliente ou vendedor
    if(url.includes('type=client') || this.$store.state.user.user.type === 'CLIENT') {
      this.$store.dispatch('updateIsClient', true)
    } else {
      this.$store.dispatch('updateIsClient', false)
    }

   
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  mounted() {
    this.existExternalParams()
  },
  methods: {
    existExternalParams() {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const code = urlParams.get("code");
      const parent_id = urlParams.get("parent_id");
      if (parent_id && code && this.$route.name !== 'VerificaEmailHash' && this.$route.name !== 'VerificaEmail') {
        this.$root.$emit("bv::show::modal", "modal-group-accept", "#btnShow");
      }
    },
    detectOs() {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;

      if (/android/i.test(userAgent)) {
        return "Android";
      }
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "iOS";
      }

      return "Desktop";
    },
    handleResize() {
      // padrão
      if(this.client.width === 0) this.client.width = window.innerWidth;
      if(this.client.width > 0) this.client.width = window.screen.width;
    },
    selectCountry(locale) {
      localStorage.setItem('locationadm', locale)
      this.search = ''
      if (locale === 'BR' || locale === 'pt' || !locale) {
        this.$i18n.locale = "pt";
      } else if (locale == "AR" ||
        locale == "CO" ||
        locale == "CL" ||
        locale == "EC" ||
        locale == "GT" ||
        locale == "MX" ||
        locale == "PE" ||
        locale == "UY" ||
        locale == "ES") {
        this.$i18n.locale = "es"
      } else {
        this.$i18n.locale = "en"
      }
    },
    
  },
  watch: {
    $route(to) {
      if (typeof to.meta.sidebar != "undefined") {
        this.sidebar = to.meta.sidebar;
      } else {
        this.sidebar = true;
      }

      document.querySelector("body").style = "overflow: auto !important; ";
    },
  },
};
</script>

<style lang="scss">
* {
  color: #333;
}
ul {
  list-style: none;
}
a {
  text-decoration: none;
}
.tranz {
  transform: translateZ(0);
}
.full-height {
  height: 100vh;
}
#app {
  flex-wrap: wrap;
}
.main {
  flex: 1;
  padding: 0 40px;
}
.main.mobile {
  margin-top: 80px;
  padding: 0 15px;
  max-width: 100% !important;
}
[data-anima="mobile"] {
  animation: op 0.5s forwards;
}
@keyframes op {
  from {
    opacity: 0;
    transform: rotate(180deg);
  }
  to {
    opacity: 1;
    transform: rotate(0);
  }
}
[data-anima="top"] {
  animation: showTop 0.5s forwards;
}
@keyframes showTop {
  from {
    opacity: 0;
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
[data-anima="bottom"] {
  animation: showBottom 0.5s forwards;
}
@keyframes showBottom {
  from {
    opacity: 0;
    transform: translate3d(0, 20px, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
[data-anima="left"] {
  animation: showLeft 0.5s forwards;
}
@keyframes showLeft {
  from {
    opacity: 0;
    transform: translate3d(-20px, 0, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
[data-anima="right"] {
  animation: showRight 0.5s forwards;
}
@keyframes showRight {
  from {
    opacity: 0;
    transform: translate3d(20px, 0, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

[data-anima="sidebar"] {
  animation: showSidebar 0.3s forwards;
}
@keyframes showSidebar {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

header .sub-menu{
  margin-top: 0 !important;
}

.menu-relative .sub-menu3{
  top: inherit !important;
  position: fixed !important;
  margin-top: 0px !important;
}

.container {
  max-width: 1150px !important;
  margin: 0 auto !important;
  padding: 0 !important;
}

// transação de entrada
.v-enter {
  opacity: 0;
  transform: translate3d(-20px, 0, 0);
}
.v-enter-active {
  transition: all 0.3s;
}

@keyframes preload {
  from {
    background: #f7f7f7;
  }

  to {
    background: rgba(237, 237, 240, 0.3);
  }
}
.wrapper {
  background: #fff !important;
  border: 0.5px #ededf0 solid !important;
  border-radius: 5px !important;
}
.nao-encontrado {
  font-size: 14px;
  color: #81858e;
  font-weight: normal;
  border-radius: 5px;
  padding: 20px !important;
  background: #f7f7f7;
  margin: 20px auto;
}
.total-vendas,
.total-itens {
  font-weight: 600;
  font-size: 16px;
  color: #333;
}
.total-vendas {
  display: flex;
  align-items: center;
}
.total-vendas span {
  font-size: 20px;
  font-weight: 600;
  color: $base-color;
  margin-left: 5px;
}
.total-vendas.heaven span {
  color: $base-color-heaven;
}

.total-vendas.info {
  font-size: 14px;
  font-weight: normal;
  color: #333;
}
// box total
.box-total {
  background: rgba(0, 148, 136, 0.1);
  padding: 30px !important;
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  border: 1px solid $base-color;
}

.box-total.heaven {
  background: $base-color-heaven-light;
  border: 1px solid $base-color-heaven;
}
@media screen and (max-width: 768px) {
  .box-total {
    padding: 20px !important;
    margin: 30px 0 10px 0 !important;
  }
  .total-vendas,
  .total-itens {
    font-size: 14px;
  }
  .total-vendas span {
    font-size: 16px;
  }
  .total-vendas.info {
    font-size: 13px;
  }
}
.ql-toolbar.ql-snow {
  border-color: #ededf0 !important;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  font-family: Montserrat, sans-serif;
}
.ql-container.ql-snow {
  border-color: #ededf0 !important;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  font-family: Montserrat, sans-serif;
}
.ql-editor {
  height: 12vh !important;
}
.ql-editor p {
  font-size: 14px !important;
}

.sweet-container {
  padding: 50px 40px !important;
}
.sweet-container h2 {
  font-size: 20px !important;
  margin-bottom: 20px !important;
}
.sweet-container #swal2-content {
  font-size: 16px !important;
  color: #81858e !important;
  line-height: 1.5 !important;
}
.sweet-container .swal2-checkbox {
  display: none !important;
}
@media screen and (max-width: 768px) {
  .swal2-header {
    align-items: flex-start !important;
  }
  .sweet-container h2 {
    font-size: 16px !important;
    text-align: left !important;
  }
  .sweet-container #swal2-content {
    font-size: 14px !important;
    text-align: left !important;
  }
}
.dropdown-item {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
  font-size: 15px !important;
}
.dropdown-item.active,
.dropdown-item:active {
  background: $base-color !important;
}
.heaven .dropdown-item.active,
.heaven .dropdown-item:active {
  background: $base-color-heaven !important;
}
.v-enter,
.v-leave-to {
  opacity: 0;
}
.v-enter {
  transform: translate3d(0, -20px, 0);
}
.v-leave-to {
  transform: translate3d(0, 20px, 0);
}
.v-enter-active,
.v-leave-active {
  transition: all 0.3s;
}
.invalid-feedback {
  color: #ff0c37 !important;
}
.image-list-container {
  display: flex;
  flex-wrap: wrap;
  width: 190px !important;
  max-width: 190px !important;
}

// notificações
.toast {
  max-width: 400px !important;
  font-size: 14px !important;
  background-color: hsla(0, 0%, 100%, 0.85);
  background-clip: initial !important;
  border: 1px solid;
  box-shadow: none !important;
  border-radius: 5px !important;
  backdrop-filter: blur(0) !important;
  padding: 20px 30px;
  padding-left: 50px;
  font-family: Montserrat, sans-serif;
  outline: none !important;
  animation: showRight 0.3s forwards !important;
}
.b-toast,
.b-toaster-slot {
  max-width: 400px !important;
}
.b-toaster,
.swal2-container {
  z-index: 9999999 !important;
}
.toast-header,
.toast-body {
  padding: 0 !important;
  position: relative;
  line-height: 1.5 !important;
  font-size: 14px !important;
}
.toast-body {
  color: #5f5c6b !important;
}
.toast .close {
  font-weight: 500 !important;
  line-height: 1;
  color: #5f5c6b;
  text-shadow: none !important;
  opacity: 0.5;
  position: absolute !important;
  top: -10px !important;
  right: -10px !important;
  outline: none !important;
}

.toast-header strong {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  font-weight: 600 !important;
}
.toast-header strong::before {
  content: "";
  background: url("./assets/img/icons/notify_default.svg") no-repeat center
    center;
  width: 20px;
  height: 20px;
  display: inline-block;
  position: absolute;
  left: -30px;
}
// sucesso
.b-toast-success .toast-header strong {
  color: #009488;
}
.b-toast-success .toast-header strong::before {
  background: url("./assets/img/icons/notify_success.svg") no-repeat center;
}
.b-toast-success .toast {
  background-color: #f2fefa !important;
  border-color: #009488 !important;
  color: #009488 !important;
}
.b-toast-success .toast .toast-header {
  color: #009488 !important;
  background-color: #f2fefa !important;
  border: none !important;
}
.b-toast-success.b-toast-solid .toast {
  background-color: #f2fefa !important;
}

// info
.b-toast-info .toast-header strong {
  color: #2133d2;
}
.b-toast-info .toast-header strong::before {
  background: url("./assets/img/icons/notify_info.svg") no-repeat center;
}
.b-toast-info .toast {
  background-color: #f4f5fd !important;
  border-color: #2133d2 !important;
  color: #2133d2 !important;
}
.b-toast-info .toast .toast-header {
  color: #2133d2 !important;
  background-color: #f4f5fd !important;
  border: none !important;
}
.b-toast-info.b-toast-solid .toast {
  background-color: #f4f5fd !important;
}

// danger
.b-toast-danger .toast-header strong {
  color: #ff0c37;
}
.b-toast-danger .toast-header strong::before {
  background: url("./assets/img/icons/notify_danger.svg") no-repeat center;
}
.b-toast-danger .toast {
  background-color: #fff3f5 !important;
  border-color: #ff0c37 !important;
  color: #ff0c37 !important;
}
.b-toast-danger .toast .toast-header {
  color: #ff0c37 !important;
  background-color: #fff3f5 !important;
  border: none !important;
}
.b-toast-danger.b-toast-solid .toast {
  background-color: #fff3f5 !important;
}

// warning
.b-toast-warning .toast-header strong {
  color: #FF9E44;
}
.b-toast-warning .toast-header strong::before {
  background: url("./assets/img/icons/notify_warning.svg") no-repeat center;
}
.b-toast-warning .toast {
  background-color: #fffcf5 !important;
  border-color: #FF9E44 !important;
  color: #FF9E44 !important;
}
.b-toast-warning .toast .toast-header {
  color: #FF9E44 !important;
  background-color: #fffcf5 !important;
  border: none !important;
}
.b-toast-warning.b-toast-solid .toast {
  background-color: #fffcf5 !important;
}

// default
.toast-header strong {
  color: #333;
}
.toast-header strong::before {
  background: url("./assets/img/icons/notify_default.svg") no-repeat center;
}
.toast {
  background-color: #f6f6f7 !important;
  border-color: #333 !important;
  color: #333 !important;
}
.toast .toast-header {
  color: #333 !important;
  background-color: #f6f6f7 !important;
  border: none !important;
}
.b-toast-solid .toast {
  background-color: #f6f6f7 !important;
}

.info-checkbox {
  margin: 0;
  margin-top: 20px;
  margin-left: 5px;
  font-weight: 500;
}
.descricao-checkbox {
  display: block;
  margin-top: 5px;
  font-weight: normal;
  color: #81858e;
  font-size: 13px;
  max-width: 80%;
  line-height: 1.5;
}

// markDown
.description-mark {
  margin: 30px auto !important;
}
.description-mark.preview .github-markdown-body {
  padding: 0;
}
.description-mark h1,
.description-mark h2,
.description-mark h3,
.description-mark h4,
.description-mark h5,
.description-mark h6 {
  padding-bottom: 15px !important;
  border-bottom: 1px solid #dce6f0 !important;
  margin: 40px 0 20px 0;
  font-weight: 600;
}
.description-mark h1 {
  font-size: 2rem;
}
.description-mark h2 {
  font-size: 1.8rem;
}
.description-mark p {
  font-size: 14px;
  color: #81858e;
  line-height: 1.5;
  margin: 20px 0 !important;
  font-weight: normal;
}
.description-mark ul,
.description-mark ol {
  margin: 10px 0;
}
.description-mark li + li {
  margin-top: 10px;
}
.description-mark blockquote {
  padding: 0 1rem;
  margin-left: 0;
  color: #81858e;
  border-left: 0.3rem solid #ededf0;
}
.description-mark code {
  margin-top: 1rem;
  display: block;
  padding: 1rem;
  font-family: Consolas, "Liberation Mono", Menlo, Courier, monospace;
  font-size: 0.9rem;
  color: #567482;
  background-color: #f3f6fa;
  border-radius: 0.3rem;
}
.description-mark table {
  border-collapse: collapse;
  border-spacing: 0;
  display: block;
  width: 100%;
  overflow: auto;
  word-break: normal;
  word-break: keep-all;
  -webkit-overflow-scrolling: touch;
}
.description-mark table th,
.description-mark table td {
  padding: 0.5rem 1rem;
  border: 1px solid #ededf0;
}

// preview
.description-mark.preview h1,
.description-mark.preview h2 {
  font-size: 1.3rem !important;
}

.description-mark.preview * {
  font-size: 13px !important;
}
.v-md-textarea-editor textarea {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100% !important;
  border: none !important;
  outline: none !important;
  resize: none;
}
.v-md-editor {
  border: 1px solid #ddd;
  box-shadow: none !important;
}
.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
#modal-pf-account___BV_modal_header_,
#modal-id_modal_account_type___BV_modal_header_{
  padding-bottom:0px !important;
}

.selectProduct{
  .multiselect__content {
    position: inherit !important;    
  }
}

// Date-Range-Picker
.monthselect.col{
  background: white !important;
  padding: 10px !important;

}
.monthselect,
  .yearselect,
  .hourselect,
  .minuteselect {
    background-color: transparent;
    text-align: center;
  }

.daterangepicker .ranges {
  width: 100% !important;
}
.daterangepicker {
  border-radius: 10px !important;
  border-color: #ededf0 !important;
}
.daterangepicker .calendar-table .next span,
.daterangepicker .calendar-table .prev span {
  border-color: #009488 !important;
}
.daterangepicker .drp-buttons {
  border-color: #ededf0 !important;
}

.daterangepicker .ranges li {
  border-radius: 7px;
  transition: all 0.3s;
}

.daterangepicker tr td.in-range {
  background-color: #0094882c;
}

.daterangepicker .btn-primary,
.daterangepicker .btn-success {
  background-color: #009488 !important;
  font-weight: 500 !important;
  border-radius: 7px;
  padding: 5px 10px !important;
  border-color: #009488 !important;
  &:focus{
    box-shadow: 0 0 0 0.2rem #0094882c !important;
  }
}

.daterangepicker td.off,
.daterangepicker td.off.end-date,
.daterangepicker td.off.in-range,
.daterangepicker td.off.start-date {
  background-color: transparent !important;
}

.daterangepicker td.active,
.daterangepicker td.active:hover {
  background-color: #009488 !important;
}

.daterangepicker .ranges {
  li:hover{
    background-color: #0094882c !important;
  }
  li.active{
    background-color: #009488 !important;
    &:focus-visible{
      outline:none !important;
    }
    &:hover{
      background-color: #009488 !important;
    }
  }
}

.daterangepicker.show-ranges .drp-calendar.left{
  border-left: 1px solid transparent !important;
}

.daterangepicker .btn-secondary {
  color: var(--gray01) !important;
  background: #f7f7f7 !important;
  font-weight: 500 !important;
  border-radius: 7px;
  font-size: 14px !important;
  padding: 5px 10px !important;
}

.datas .vue-daterange-picker {
  width: 180px !important;
  font-size: 14px !important;
  color: #81858e !important;
}
</style>

