import Vue from 'vue';
import Router from 'vue-router';
import routes from './routes';
import PingService from '@/services/resources/PingService';
const pingService = PingService.build();
import store from '@/store';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes,
});

router.afterEach(() => {
  pingService.create();
});

/*
  Hook global para garantir que o parâmetro "cashback" seja mantido durante a navegação.
  Isso é importante para garantir que o valor de cashback não seja perdido ao navegar entre páginas,
  especialmente em rotas como boas-vindas e painel (dashboard) onde o hash do cashback precisa circular.
*/
router.beforeEach((to, from, next) => {
  const isWelcomeOrDashboard = to.path === '/boas-vindas' || to.path === '/dashboard';

  if(isWelcomeOrDashboard){
      const cashbackParam = from.query.cashback;
      const queryParams = { ...to.query };
      const hasCashbackInNextRoute = 'cashback' in queryParams;

      if (cashbackParam && !hasCashbackInNextRoute) {
        queryParams.cashback = cashbackParam;
        router.replace({ ...to, query: queryParams });
        return false;
      }
  }
  if (from.name === 'EditProduct') {
    store.dispatch('clearTimeoutId');
  }
  if (to.name === 'EditProduct') {
    store.commit('setFetchingStatus', true);
  }
  next();
});

export default router;