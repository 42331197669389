var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-menu"},[(!_vm.$store.getters.setPanel || (_vm.$store.getters.setPanel && _vm.dashboard))?_c('item',{attrs:{"route":"Dashboard","name":"Dashboard","icon":"dashboard"}},[_vm._v("Dashboard")]):_vm._e(),(
      (!_vm.$store.getters.setPanel || (_vm.$store.getters.setPanel && _vm.vendas)) &&
      !_vm.isClient
    )?_c('item',{attrs:{"route":"Sales","name":_vm.$t('side.text_1952'),"icon":"my-sales"}}):_vm._e(),(
      (!_vm.$store.getters.setPanel || (_vm.$store.getters.setPanel && _vm.contratos)) &&
      !_vm.isClient
    )?_c('item',{attrs:{"route":"Contracts","name":_vm.$t('side.text_1953'),"icon":"my-contracts"}}):_vm._e(),(!_vm.$store.getters.setPanel && !_vm.isClient)?_c('item',{attrs:{"route":"Products","name":_vm.$t('side.text_1954'),"icon":"products"}}):_vm._e(),(
      (!_vm.$store.getters.setPanel || (_vm.$store.getters.setPanel && _vm.links)) &&
      !_vm.isClient
    )?_c('item',{attrs:{"route":"Links","name":_vm.$t('side.text_1955'),"icon":"links"}}):_vm._e(),(!_vm.$store.getters.setPanel)?_c('item',{attrs:{"route":"MyPurchases","name":_vm.$t('views.buyer.my_purchases.text_3016'),"icon":"shopping_cart"}}):_vm._e(),(!_vm.$store.getters.setPanel && !_vm.isClient)?_c('item',{attrs:{"route":"Statements","name":_vm.$t('side.text_1956'),"icon":"extrato"}}):_vm._e(),(
      (!_vm.$store.getters.setPanel || (_vm.$store.getters.setPanel && _vm.cupom)) &&
      !_vm.isClient
    )?_c('item',{attrs:{"route":"Coupon","name":_vm.$t('side.text_1957'),"icon":"cupom"}}):_vm._e(),(
      (!_vm.$store.getters.setPanel || (_vm.$store.getters.setPanel && _vm.CenterNotas)) && 
      !_vm.isClient
    )?_c('item',{attrs:{"route":"NoteCenter","name":_vm.$t('side.text_1958'),"icon":"notas"}}):_vm._e(),(!_vm.$store.getters.setPanel && !_vm.isClient)?_c('item',{attrs:{"route":"Checkouts","name":_vm.$t('side.text_1959'),"icon":"grid"}}):_vm._e(),(!_vm.$store.getters.setPanel && !_vm.isClient)?_c('item',{attrs:{"route":"Affiliation","name":_vm.$t('side.text_1960'),"icon":"affiliate"}}):_vm._e(),(!_vm.$store.getters.setPanel && !_vm.isClient)?_c('item',{attrs:{"route":"Leads","name":_vm.$t('side.text_1961'),"icon":"award"}}):_vm._e(),(!_vm.$store.getters.setPanel && !_vm.isClient)?_c('item',{attrs:{"route":"Reclamações","name":_vm.$t('side.text_1962'),"icon":"triangle"}}):_vm._e(),(!_vm.$store.getters.setPanel && !_vm.isClient && !_vm.isHeaven && _vm.indication)?_c('item',{attrs:{"route":"Indicacoes","name":'Greenn Partner',"icon":"indication_menu"}}):_vm._e(),(!_vm.$store.getters.setPanel && !_vm.isClient)?_c('item',{attrs:{"route":"MyAccount","name":_vm.$t('side.text_1949'),"icon":"user-config"}}):_vm._e(),(!_vm.$store.getters.setPanel && !_vm.isClient)?_c('item',{attrs:{"route":"Settings","name":_vm.$t('side.text_1963'),"icon":"company-config"}}):_vm._e(),(
      !_vm.$store.getters.setPanel &&
      !!_vm.$store.state.user.user.is_ambassador &&
      !_vm.isClient
    )?_c('item',{attrs:{"route":"Ambassador","name":_vm.$t('side.text_1976'),"icon":"ambassador"}}):_vm._e(),_c('div',{staticClass:"Menu-item-logout",on:{"click":function($event){$event.preventDefault();return _vm.logout.apply(null, arguments)}}},[_c('a',{attrs:{"href":"#"}},[_c('img',{attrs:{"src":require("@/assets/img/icons/sign-out.svg")}}),_vm._v(" "+_vm._s(_vm.$t("side.text_1964"))+" ")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }