<template>
  <div class="Menu-item" :class="{ active: active == route }" @click="click">
    <router-link :to="{ name: route }">
      <div style="display: flex;align-items: center;">
        <img :src="image" />
      </div>
      <div>
        {{ name }}
      </div>
    </router-link>
  </div>
</template>
<script>
export default {
  props: {
    route: {
      type: String,
      default: "",
    },
    icon: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
  },
  computed: {
    active() {
      return this.$route.name;
    },
    image() {
      return require(`@/assets/img/icons/${this.icon}.svg`);
    },
  },
  methods: {
    click() {
      setTimeout(() => {
        document.querySelector(".btn-menu").click();
      }, 500);
    },
  },
};
</script>

<style lang="scss" scoped>
.Menu-item {
  display: flex;
  align-items: center;
  background-color: #fff;
  border-radius: 5px;
  margin-bottom: 2px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);

  a {
    width: 100%;
    padding: 20px;
    display: block;
    text-decoration: none;
    color: #333;
    font-size: 13.5px;
    font-weight: 600;

    img {
      margin-right: 20px;
      width: 17px;
    }
  }

  &:hover {
    background-color: $base-color;
    a {
      color: #fff;

      img {
        filter: invert(100%) brightness(1000%);
      }
    }
    cursor: pointer;
  }
}
.active {
  background-color: $base-color;

  a {
    color: #fff;

    img {
      filter: invert(100%) brightness(1000%);
    }
  }
  cursor: pointer;
}
</style>