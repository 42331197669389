<template>
  <div
    class="menu-links"
    :class="{
      'ajust-itens': $store.getters.setPanel || isClient,
      heaven: isHeaven,
    }"
  >
    <!-- Dashboard -->
    <router-link
      v-if="!$store.getters.setPanel || ($store.getters.setPanel && dashboard)"
      to="/dashboard"
    >
      <img v-if="!isHeaven" src="@/assets/img/icons/dashboard.svg" />
      <img v-else src="@/assets/img/icons-heaven/dashboard.svg" />
      Dashboard
    </router-link>

    <!-- Sales -->
    <a
      v-if="
        (!$store.getters.setPanel || ($store.getters.setPanel && vendas)) &&
        !isClient
      "
      @click="subMenu01"
    >
      <img v-if="!isHeaven" src="@/assets/img/icons/sales.svg" />
      <img v-else src="@/assets/img/icons-heaven/sales.svg" />
      {{ $t("side.text_1952") }}
      <img
        src="@/assets/img/icons/arrow-fill.svg"
        class="arrow"
        :class="{ rotateArrow: sub_menu }"
      />
    </a>
    <ul class="sub-menu" id="sub_01" v-show="sub_menu && !isClient">
      <li @click="sub_menu = false">
        <!-- My Sales -->
        <router-link to="/vendas">
          <img v-if="!isHeaven" src="@/assets/img/icons/my-sales.svg" />
          <img v-else src="@/assets/img/icons-heaven/my-sales.svg" />
          {{ $t("side.text_1965") }}
        </router-link>
        <!-- My Contracts -->
        <router-link
          v-if="
            !$store.getters.setPanel || ($store.getters.setPanel && contratos)
          "
          to="/contratos"
        >
          <img v-if="!isHeaven" src="@/assets/img/icons/my-contracts.svg" />
          <img v-else src="@/assets/img/icons-heaven/my-contracts.svg" />
          {{ $t("side.text_1966") }}
        </router-link>
      </li>
    </ul>
    <!-- Reports -->
    <router-link
      v-if="!$store.getters.setPanel && !isHeaven && !isClient"
      to="/relatorios"
    >
      <img v-if="!isHeaven" src="@/assets/img/icons/relatorios.svg" />
      <img v-else src="@/assets/img/icons-heaven/relatorios.svg" />
      {{ $t("side.text_1969") }}
    </router-link>
    <!-- Products -->
    <router-link
      v-if="
        (!$store.getters.setPanel || ($store.getters.setPanel && products)) &&
        !isClient
      "
      to="/produtos"
    >
      <img v-if="!isHeaven" src="@/assets/img/icons/products.svg" />
      <img v-else src="@/assets/img/icons-heaven/products.svg" />
      {{ $t("side.text_1954") }}
    </router-link>
    <!-- Links -->
    <router-link
      v-if="
        (!$store.getters.setPanel || ($store.getters.setPanel && links)) &&
        !isClient
      "
      to="/links"
    >
      <img v-if="!isHeaven" src="@/assets/img/icons/links.svg" />
      <img v-else src="@/assets/img/icons-heaven/links.svg" />
      {{ $t("side.text_1955") }}
    </router-link>

    <!-- My Purchases -->
    <router-link v-if="!$store.getters.setPanel" to="/minhas-compras">
      <img v-if="!isHeaven" src="@/assets/img/icons/shopping_cart.svg" />
      <img v-else src="@/assets/img/icons-heaven/shopping_cart.svg" />
      {{ $t("views.buyer.my_purchases.text_3016") }}
    </router-link>

    <!-- See More -->
    <a
      v-if="
        (!$store.getters.setPanel ||
          ($store.getters.setPanel && cupom) ||
          ($store.getters.setPanel && leads)) &&
        !isClient
      "
      @click="subMenu02"
    >
      <img v-if="!isHeaven" src="@/assets/img/icons/more.svg" />
      <img v-else src="@/assets/img/icons-heaven/more.svg" />
      {{ $t("side.text_1970") }}
      <img
        src="@/assets/img/icons/arrow-fill.svg"
        class="arrow"
        :class="{ rotateArrow: sub_menu2 }"
      />
    </a>
    <ul v-show="sub_menu2 && !isClient" id="sub_02" class="sub-menu sub-menu2">
      <li @click="sub_menu2 = false">
        <!-- Greenn / Heaven -->
        <!-- <template v-if="interAccess && !$store.getters.setPanel">
          <a v-if="isHeaven" @click="loginGreenn">
            <img src="@/assets/img/icons-heaven/arrow-club.svg" />
            <img class="logo-greenn-heaven" src="@/assets/logo.png" />
          </a>
          <a v-else @click="loginHeaven">
            <img src="@/assets/img/icons/arrow-club.svg" />
            <img
              class="logo-greenn-heaven"
              src="@/assets/img/icons-heaven/logo.svg"
            />
          </a>
        </template> -->
        <!-- Greenn Club -->
        <b-tooltip
          target="club-menu"
          :title="$t('side.text_1971')"
          placement="left"
        />
        <a
          v-if="!$store.getters.setPanel"
          id="club-menu"
          @click="eventLoginClub"
        >
          <img v-if="!isHeaven" src="@/assets/img/icons/arrow-club.svg" />
          <img v-else src="@/assets/img/icons-heaven/arrow-club.svg" />
          <img class="logo-club" src="@/assets/img/logo-club.png" />
        </a>
        <!-- Greenn Envios -->
        <a v-if="!$store.getters.setPanel" @click="fetchGreennEnvios" class="greenn-envios">
          <img src="@/assets/img/delivery-options/envios.svg" alt="Greenn Envios logo" />
          <template v-if="!loadingEnvios">Greenn Envios</template>
          <b-spinner v-else label="Loading..."></b-spinner>
        </a>
        <!-- Extract -->
        <router-link to="/extrato" v-if="!$store.getters.setPanel">
          <img v-if="!isHeaven" src="@/assets/img/icons/extrato.svg" />
          <img v-else src="@/assets/img/icons-heaven/extrato.svg" />
          {{ $t("side.text_1956") }}
        </router-link>
        <!-- Coupon -->
        <router-link
          v-if="!$store.getters.setPanel || ($store.getters.setPanel && cupom)"
          to="/cupom"
        >
          <img v-if="!isHeaven" src="@/assets/img/icons/cupom.svg" />
          <img v-else src="@/assets/img/icons-heaven/cupom.svg" />
          {{ $t("side.text_1972") }}
        </router-link>
        <!-- Central Notes -->
        <!-- <router-link v-if="!$store.getters.setPanel && !isHeaven && CenterNotas || ($store.getters.setPanel && leads)" to="/central-de-notas"> -->
        <router-link v-if="!$store.getters.setPanel || ($store.getters.setPanel && CenterNotas)" to="/central-de-notas">
          <img v-if="!isHeaven" src="@/assets/img/icons/notas.svg" />
          <img v-else src="@/assets/img/icons-heaven/notas.svg" />
          {{ $t('side.text_1958') }}
        </router-link>
        <!-- Checkouts -->
        <router-link to="/checkouts" v-if="!$store.getters.setPanel">
          <img v-if="!isHeaven" src="@/assets/img/icons/grid.svg" />
          <img v-else src="@/assets/img/icons-heaven/grid.svg" />
          {{ $t("side.text_1959") }}
        </router-link>
        <!-- Affiliated -->
        <router-link to="/afiliados" v-if="!$store.getters.setPanel">
          <img v-if="!isHeaven" src="@/assets/img/icons/affiliate.svg" />
          <img v-else src="@/assets/img/icons-heaven/affiliate.svg" />
          {{ $t("side.text_1960") }}
        </router-link>
        <!-- Leads -->
        <router-link
          to="/gestao-leads"
          v-if="!$store.getters.setPanel || ($store.getters.setPanel && leads)"
        >
          <img v-if="!isHeaven" src="@/assets/img/icons/award.svg" />
          <img v-else src="@/assets/img/icons-heaven/award.svg" />
          {{ $t("side.text_1961") }}
        </router-link>
        <!-- Claims -->
        <router-link to="/reclamacoes" v-if="!$store.getters.setPanel">
          <img v-if="!isHeaven" src="@/assets/img/icons/triangle.svg" />
          <img v-else src="@/assets/img/icons-heaven/triangle.svg" />
          {{ $t("side.text_1962") }}
        </router-link>
        <!-- Indications -->
        <router-link
          to="/indications"
          v-if="!$store.getters.setPanel && !isHeaven && indication"
        >
          <img src="@/assets/img/icons/indication_menu.svg" />
          Greenn Partner
        </router-link>
      </li>
    </ul>
    <!-- Ambassador -->
    <router-link
      v-if="
        !$store.getters.setPanel &&
        !!$store.state.user.user.is_ambassador &&
        !isClient
      "
      to="/embaixador"
      ambassador
    >
      <img src="@/assets/img/icons/ambassador.svg" />
      {{ $t("side.text_1976") }}
    </router-link>

    <Logout />

    <ModalGreenn />
    <ModalHeaven />
  </div>
</template>

<script>
import Cookies from "js-cookie";
import Vue from "vue";
import Logout from "./Logout.vue";
import ModalHeaven from "./ModalHeaven.vue";
import ModalGreenn from "./ModalGreenn.vue";
import { interAccess } from "@/helpers.js";

export default {
  name: "Menu",
  components: { Logout, ModalGreenn, ModalHeaven },
  data() {
    return {
      sub_menu: false,
      sub_menu2: false,
      item: null,
      top: 0,
      bottom: 0,
      fimMenu: 300,
      fimSubmenu: 0,
      dashboard: false,
      vendas: false,
      cupom: false,
      contratos: false,
      CenterNotas: false,
      leads: false,
      products: false,
      links: false,
      interAccess: false,
      loadingEnvios: false,
    };
  },
  computed: {
    panelState() {
      return this.$store.getters.setPanel;
    },
    clubIntegration() {
      return this.$store.getters.userClub;
    },
    isHeaven() {
      return this.$store.getters["getIsHeaven"];
    },
    user() {
      return this.$store.getters.getProfile;
    },
    isClient() {
      return this.$store.getters.getProfile.type === "CLIENT";
    },
    indication() {
      return this.$store.getters.getIndication;
    },
  },
  methods: {
    getPermissions() {
      if (this.$store.getters.setPanel) {
        let dados = Cookies.get("setPanelPermission");
        if (dados) {
          dados = JSON.parse(dados);
          if (dados.find((x) => x.name === "DashboardRead")) {
            this.dashboard = true;
          }
          if (dados.find((x) => x.name === "SaleRead")) {
            this.vendas = true;
          }
          if (dados.find((x) => x.name === "CouponRead")) {
            this.cupom = true;
          }
          if (dados.find((x) => x.name === "ContractRead")) {
            this.contratos = true;
          }
          if (dados.find((x) => x.name === "LeadRead")) {
            this.leads = true;
          }
          if (dados.find((x) => x.name === "ProductRead")) {
            this.products = true;
          }
          if (dados.find((x) => x.name === "CenterNotasRead")) {
            this.CenterNotas = true;
          }
          if (dados.find((x) => x.name === "LinkRead")) {
            this.links = true;
          }
        }
      }
    },
    eventLoginClub() {
      Vue.swal({
        title: this.$t("side.text_1973"),
        text: this.$t("side.text_1971"),
        type: "info",
        showCancelButton: true,
        confirmButtonText: "Entrar no Club",
        cancelButtonText: this.$t("side.text_1975"),
        customClass: "sweet-container",
        confirmButtonClass: "button button-black mt-3 mb-3",
        cancelButtonClass: "button button-link-info mr-4 mt-3 mb-3",
        buttonsStyling: false,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store.dispatch("clubLogin").catch(function () {});
        }
        if (result.dismiss == "cancel") {
          this.$router.push("/produtos");
        }
      });
    },
    subMenu01(e) {
      this.item = e.currentTarget;
      this.top = this.item.offsetTop;
      const submenu = document.querySelector("#sub_01");
      submenu.style = `top: ${this.top}px`;
      this.bottom = this.top + submenu.offsetHeight;
      this.fimSubmenu = this.fimMenu + submenu.offsetLeft - 71;

      this.sub_menu = !this.sub_menu;
      this.sub_menu2 = false;
    },
    subMenu02(e) {
      this.item = e.currentTarget;
      this.top = this.$store.getters.setPanel
        ? this.item.offsetTop
        : this.item.offsetTop - 400;
      this.top = this.top - 100;
      const submenu = document.querySelector("#sub_02");
      submenu.style = `top: ${this.top}px`;

      this.bottom = this.top + submenu.offsetHeight;
      this.fimSubmenu = this.fimMenu + submenu.offsetLeft - 20;

      this.sub_menu2 = !this.sub_menu2;
      this.sub_menu = false;
    },
    eventMouse() {
      window.addEventListener("mousemove", (e) => {
        if (
          e.clientX > this.fimSubmenu ||
          (e.clientX > this.fimMenu && e.clientY < this.top) ||
          (e.clientX > this.fimMenu && e.clientY > this.bottom)
        ) {
          this.sub_menu = false;
          this.sub_menu2 = false;
        }
      });
    },
    async loginGreenn() {
      try {
        let res = await this.$store.dispatch("userRequest");
        if (res.is_greenn === 1) {
          this.$store.dispatch("generateHash").then((resp) => {
            window.location.href =
              process.env.VUE_ADM_PAGE + "/?rdr=" + resp.ck;
          });
        } else {
          this.$bvModal.show("solicitation-greenn");
        }
      } catch (err) {
        console.error(err);
      }
    },
    async loginHeaven() {
      try {
        let res = await this.$store.dispatch("userRequest");
        if (res.is_heaven === 1) {
          this.$store.dispatch("generateHash").then((resp) => {
            window.location.href =
              process.env.VUE_ADM_HEAVEN_PAGE + "/?rdr=" + resp.ck;
          });
        } else {
          this.$bvModal.show("solicitation-heaven");
        }
      } catch (err) {
        console.error(err);
      }
    },
    async fetchGreennEnvios() {
      this.loadingEnvios = true;
      this.$store
        .dispatch("authGreennEnvios")
        .then((resp) => {
          if (resp.string) {
            window.open(
              process.env.VUE_ENVIOS_API_HOST +
                "/login?rdr=" +
                resp.string +
                "&type=greenn",
              "_blank"
            );
          }
          if(resp.update){
            this.fetchGreennEnvios();
          }
        })
        .catch(err => {
          console.error(err);
        })
        .finally(() => {
          this.loadingEnvios = false;
        });
    },
  },
  mounted() {
    this.interAccess = interAccess(this.user.id);
    this.eventMouse();
    this.getPermissions();
    this.$root.$on("reset-all", (data) => {
      this.getPermissions();
    });
    this.$root.$on("reset-permissions", (data) => {
      this.getPermissions();
    });
  },
};
</script>

<style scoped lang="scss">
.ajust-itens {
  height: 15vh !important;
}
.menu-links {
  display: flex;
  flex-direction: column;
  height: 60vh;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  padding: 0 !important;
}
.menu-links a {
  position: relative;
  display: flex;
  align-items: center;
  min-width: 230px;
  padding: 10px;
  padding-left: 0;
  cursor: pointer;
  color: #333;
  font-weight: 600;
  text-decoration: none;
  transition: all 0.3s ease;
  font-size: 15px;
  color: transparent;
  margin: 0 auto;
}
.menu-links a:hover {
  text-decoration: none;
  color: $base-color;
}
.menu-links a.greenn-envios:hover {
  text-decoration: none;
  color: #2133D2 !important;
}
.menu-links.heaven a:hover {
  color: $base-color-heaven;
}
.menu-links a:not([ambassador]) img {
  filter: invert(50%);
  transition: all 0.3s ease;
  margin: 0;
  margin-right: 40px;
  max-width: 22px;
}
.menu-links a[ambassador] img {
  transition: all 0.3s ease;
  margin: 0;
  margin-right: 40px;
  max-width: 22px;
}

.menu-links a.router-link-exact-active img,
.menu-links a:hover img {
  filter: invert(0);
}
.menu-links a.router-link-exact-active,
.menu-links a:hover {
  color: $base-color !important;
}
.menu-links.heaven a.router-link-exact-active,
.menu-links.heaven a:hover {
  color: $base-color-heaven !important;
}
.menu-links a[ambassador].router-link-exact-active,
.menu-links a[ambassador]:hover {
  color: #FF9E44 !important;
}
.menu-links a.router-link-exact-active::before {
  content: "";
  width: 7px;
  height: 7px;
  background: $base-color;
  border-radius: 50%;
  display: block;
  position: absolute;
  left: -25px;
  transition: all 0.3s ease;
}
.menu-links.heaven a.router-link-exact-active::before {
  background: $base-color-heaven;
}
img.arrow {
  margin-left: 10px !important;
}
/* Sub menu */
.sub-menu {
  width: 230px;
  box-shadow: 2px 6px 10px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  background: #fff;
  padding: 25px;
  position: fixed;
  left: 200px;
  z-index: 55555 !important;
  animation: showLeft 0.3s forwards;
}
#sub_02 {
  width: 280px;
}
#sub_03 {
  width: 280px;
}

.sub-menu li a {
  font-weight: 600;
  color: #333;
  font-size: 14px;
  padding: 10px;
}
.sub-menu li a.router-link-exact-active::before {
  left: -15px;
}
.sub-menu li a:hover {
  text-decoration: none;
  color: #000;
}
.sub-menu li a + a {
  margin-top: 20px;
}
.sub-menu li a img {
  margin-right: 25px;
}
.Menu:hover .menu-links a {
  color: #333;
}

/* --- */
.sub-menu2 {
  top: 300px;
}
.sub-menu3 {
  top: 300px;
}
.rotateArrow {
  transform: rotate(-90deg);
}

.logo-club {
  max-width: 115px !important;
  width: 115px;
}

.logo-greenn-heaven {
  max-width: 115px !important;
  width: 90px;
}

.ajust-itens {
  justify-content: flex-start;
  gap: 40px;
}
</style>
