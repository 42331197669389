<template>
  <section class="menu" :class="{ ativo: !menu_mobile }">
    <header class="menu__header">
      <router-link to="/">
        <img class="menu__header__logo" v-if="!isHeaven" src="@/assets/logo.png" />
        <img class="menu__header__logo" v-else src="@/assets/img/icons-heaven/logo.svg" />
      </router-link>
      <div class="btn-menu" @click="menu_mobile = !menu_mobile">
        {{ menu_mobile ? "Menu" : "Fechar" }}
        <img
          :src="menu_mobile ? require('@/assets/img/icons/menu.svg') : require('@/assets/img/icons/close.svg')"
          data-anima="mobile"
        />
      </div>
    </header>
    <Menu v-if="!menu_mobile" data-anima="top" />
  </section>

</template>
<script>
import Menu from "./Menu";

export default {
  components: {
    Menu,
  },
  data() {
    return {
      menu_mobile: true,
      salesActive: false,
    };
  },
  computed: {
    isHeaven() {
      return this.$store.getters["getIsHeaven"]
    }
  },
  methods: {
    logout() {
      this.$store.dispatch("logoutRequest").catch(function (err) {});
    },
    maximizeSidebar() {
      this.$store.commit("changeSidebarState");
    },
  },
};
</script>

<style lang="scss" scoped>
.menu {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 70px;
  padding: 5px 15px;
  z-index: 9999;
  transition: 0.3s;
  position: fixed;
  top: 0;
  left: 0;
  background: white;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 70px;
    
    &__logo {
      width: 90px;
      height: auto;
    }
  }

  &.ativo {
    height: 100vh;
  }
}

.Menu-collapse {
  text-align: center;
  margin-bottom: 10px;
  color: $base-color;
  cursor: pointer;
  padding: 10px;
}

.btn-menu {
  padding: 15px;
  padding-right: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 12px;
  text-transform: uppercase;
}
.btn-menu img {
  margin-left: 10px;
  transition: 0.3s;
  width: auto !important;
}
</style>
