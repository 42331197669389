<template>
  <div class="container-menu">
    <item
      v-if="!$store.getters.setPanel || ($store.getters.setPanel && dashboard)"
      route="Dashboard"
      name="Dashboard"
      icon="dashboard"
      >Dashboard</item
    >
    <item
      v-if="
        (!$store.getters.setPanel || ($store.getters.setPanel && vendas)) &&
        !isClient
      "
      route="Sales"
      :name="$t('side.text_1952')"
      icon="my-sales"
    />
    <item
      v-if="
        (!$store.getters.setPanel || ($store.getters.setPanel && contratos)) &&
        !isClient
      "
      route="Contracts"
      :name="$t('side.text_1953')"
      icon="my-contracts"
    />
    <item
      v-if="!$store.getters.setPanel && !isClient"
      route="Products"
      :name="$t('side.text_1954')"
      icon="products"
    />
    <!-- <item route="Vitrine" name="Vitrine" icon="checkout-link" /> -->
    <item
      v-if="
        (!$store.getters.setPanel || ($store.getters.setPanel && links)) &&
        !isClient
      "
      route="Links"
      :name="$t('side.text_1955')"
      icon="links"
    />
    <item
      v-if="!$store.getters.setPanel"
      route="MyPurchases"
      :name="$t('views.buyer.my_purchases.text_3016')"
      icon="shopping_cart"
    ></item>
    <item
      v-if="!$store.getters.setPanel && !isClient"
      route="Statements"
      :name="$t('side.text_1956')"
      icon="extrato"
    />
    <item
      v-if="
        (!$store.getters.setPanel || ($store.getters.setPanel && cupom)) &&
        !isClient
      "
      route="Coupon"
      :name="$t('side.text_1957')"
      icon="cupom"
    />
    <item
      v-if="
        (!$store.getters.setPanel || ($store.getters.setPanel && CenterNotas)) && 
        !isClient
      "
      route="NoteCenter"
      :name="$t('side.text_1958')"
      icon="notas"
    />
    <item
      v-if="!$store.getters.setPanel && !isClient"
      route="Checkouts"
      :name="$t('side.text_1959')"
      icon="grid"
    />
    <item
      v-if="!$store.getters.setPanel && !isClient"
      route="Affiliation"
      :name="$t('side.text_1960')"
      icon="affiliate"
    />
    <item
      v-if="!$store.getters.setPanel && !isClient"
      route="Leads"
      :name="$t('side.text_1961')"
      icon="award"
    />
    <item
      v-if="!$store.getters.setPanel && !isClient"
      route="Reclamações"
      :name="$t('side.text_1962')"
      icon="triangle"
    />
    <item
      v-if="!$store.getters.setPanel && !isClient && !isHeaven && indication"
      route="Indicacoes"
      :name="'Greenn Partner'"
      icon="indication_menu"
    />
    <item
      v-if="!$store.getters.setPanel && !isClient"
      route="MyAccount"
      :name="$t('side.text_1949')"
      icon="user-config"
    />
    <item
      v-if="!$store.getters.setPanel && !isClient"
      route="Settings"
      :name="$t('side.text_1963')"
      icon="company-config"
    />
    <item
      v-if="
        !$store.getters.setPanel &&
        !!$store.state.user.user.is_ambassador &&
        !isClient
      "
      route="Ambassador"
      :name="$t('side.text_1976')"
      icon="ambassador"
    />
    <div class="Menu-item-logout" @click.prevent="logout">
      <a href="#">
        <img src="@/assets/img/icons/sign-out.svg" />
        {{ $t("side.text_1964") }}
      </a>
    </div>
  </div>
</template>
<script>
import Item from "./Item";
import Cookies from "js-cookie";

export default {
  name: "MenuToggleSideBar",
  components: { Item },
  data() {
    return {
      dashboard: false,
      vendas: false,
      cupom: false,
      contratos: false,
      CenterNotas: false,
      links: false,
      itemsSeller: [
        { route: "Sales", name: this.$t("side.text_1965") },
        { route: "Contracts", name: this.$t("side.text_1966") },
      ],
      itemsClient: [
        { route: "Client-Purchases", name: this.$t("side.text_1967") },
        { route: "Client-Subscriptions", name: this.$t("side.text_1968") },
      ],
    };
  },
  components: {
    Item,
  },
  computed: {
    isSeller() {
      return this.$store.getters.isSeller;
    },
    isClient() {
      return this.$store.getters.getProfile.type === "CLIENT";
    },
    user() {
      return this.$store.getters.getProfile;
    },
    indication() {
      return this.$store.getters.getIndication;
    },
    isHeaven() {
      return this.$store.getters["getIsHeaven"];
    },
  },
  methods: {
    getPermissions() {
      if (this.$store.getters.setPanel) {
        var dados = Cookies.get("setPanelPermission");
        if (dados) {
          dados = JSON.parse(dados);
          if (dados.find((x) => x.name === "DashboardRead")) {
            this.dashboard = true;
          }
          if (dados.find((x) => x.name === "SaleRead")) {
            this.vendas = true;
          }
          if (dados.find((x) => x.name === "CouponRead")) {
            this.cupom = true;
          }
          if (dados.find((x) => x.name === "ContractRead")) {
            this.contratos = true;
          }
          if (dados.find((x) => x.name === "LinkRead")) {
            this.links = true;
          }
          if (dados.find((x) => x.name === "CenterNotasRead")) {
            this.CenterNotas = true;
          }
        }
      }
    },
    logout() {
      this.$store.dispatch("logoutRequest").catch(function () {});
    },
  },
  mounted() {
    this.getPermissions();
    this.$root.$on("reset-all", (data) => {
      this.getPermissions();
    });
    this.$root.$on("reset-permissions", (data) => {
      this.getPermissions();
    });
  },
};
</script>

<style scoped>
.container-menu {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px 10px;
  width: 100%;
  overflow: auto;
  padding: 20px 0;
}
.Menu-item,
.Menu-item-logout {
  height: 70px;
}

.Menu-item a {
  color: #333 !important;
}
.Menu-item-logout {
  display: flex;
  align-items: center;
  background-color: #fff;
  border-radius: 5px;
  margin-bottom: 2px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
}
.Menu-item-logout a {
  width: 100%;
  padding: 20px;
  display: flex;
  text-decoration: none;
  color: #333;
  font-size: 13px;
  font-weight: 600;
}
.Menu-item-logout img {
  margin-right: 20px;
  width: 17px;
}
.Menu-item-logout:hover {
  background-color: #ff0c37;
  cursor: pointer;
}
.Menu-item-logout:hover a {
  color: #fff;
}
.Menu-item-logout:hover img {
  filter: brightness(50) invert(0);
}
</style>
