// Services
import ProductService from "@/services/resources/ProductService";
const serviceProduct = ProductService.build();

import AffiliateService from "@/services/resources/AffiliateService";
const serviceAffiliate = AffiliateService.build();

import CategoryService from "@/services/resources/CategoryService";
const serviceCategory = CategoryService.build();

import IntegrationService from "@/services/resources/IntegrationService";
const serviceIntegration = IntegrationService.build();

import UserSettingsService from "@/services/resources/UserSettingsService";
const serviceUserSettings = UserSettingsService.build();

import CurrencyService from '@/services/resources/CurrencyService'
const serviceCurrency = CurrencyService.build()

import CallbackService from "@/services/resources/CallbackService";
const serviceCallback = CallbackService.build();

export default {
  /**
   * @returns {void}
   * @description - This method retrieves the product information.
   */
  async fetchProduct({ state, commit, dispatch }, id = null) {
    if (!id) return;
    dispatch("setLoading", true);   

    // state adicionado para previnir duplo loading na tela de produtos
    commit('setNeedOfferLoading', false); 
    
    let data = {
      id: `adm/${id}?country_code=${state.countryCode}`,
    };
    await serviceProduct
      .read(data)
      .then((res) => {
        let fiscal_center_setting = {
          generate_after_warranty: false,
          co_producer_will_issue_note: false,
        };

        if (
          res.data.fiscal_center_setting &&
          res.data.fiscal_center_setting.length
        ) {
          res.data.fiscal_center_setting.forEach((item) => {
            switch (item.key) {
              case "GENERATE_AFTER_WARRANTY":
                fiscal_center_setting.generate_after_warranty =
                  item.value === 1;
                break;
              case "CO_PRODUCER_WILL_ISSUE_NOTES":
                fiscal_center_setting.co_producer_will_issue_note =
                  item.value === 1;
                break;
            }
          });
        }

        if(res.data.category_id === 6 || res.data.category_id === 9) {
          res.data.category_id = null
        }

        if(!res.data.items_for_invoice) {
          res.data.items_for_invoice = [{
            title: null,
            quantity: null,
            percent: null,
            ncm: null
          }]
        } else {
          res.data.items_for_invoice = JSON.parse(res.data.items_for_invoice)
        }

        if(res.data.are_non_transferable === 1) res.data.are_non_transferable = true

        if(res.data.product_type_id === 3){
          if(res.data.start_time) res.data.start_time = res.data.start_time.slice(0, -3)
          if(res.data.end_time) res.data.end_time = res.data.end_time.slice(0, -3)
        }

        commit("setProduct", {
          ...res.data,
          sales_page: res.data.links
            .filter((item) => item.name === "MAIN")
            .pop().url,
        });

        commit("setCoSellers", res.data.co_sellers);
        commit("setFiscalCenterSetting", fiscal_center_setting);
        commit("setNewThankYouPage", res.data.custom_thank_you_pages);
        commit("setOfferRedirect", res.offer_redirect);
      })
      .finally(() => {
        dispatch("setLoading", false);
      });
  },
  /**
   * @returns {void}
   * @description - This method get affiliate link
   */
  async fetchAffiliateLink({ getters, state, commit }) {
    if (!getters.productId) return;
    commit("setAffiliateLoading", true);
    let data = {
      product_id: getters.productId,
      url: "invite",
    };
    await serviceAffiliate
      .search(data)
      .then((res) => {
        commit(
          "setAffiliateLink",
          `https://adm.${getters.getIsHeaven ? "heaven" : "greenn"}.${getters.getIsHeaven ? "me" : "com.br"}/recrutamento/${res.hash}/?p_id=${getters.productId}`
        );
      })
      .finally(() => {
        commit("setAffiliateLoading", false);
      });
  },
  async fetchUserSettings({ getters, state, commit }) {
    commit("setSmartSale", false);
    commit("setCustomPeriod", false);

    let data = {
      id: getters.product?.seller_id || JSON.parse(localStorage.user).id,
    };
    const res = await serviceUserSettings.read(data);
    await commit("clearErrorCreateCelcoin");
    await commit("clearErrorKycCelcoin");

    for (const {key, value} of res) {
      if ( key == "SMART_SALE" && value == "1") {
        commit("setSmartSale", true);
      }

      if (key == "PRODUCT_CUSTOM_PERIOD" && value == "1") {
        commit("setCustomPeriod", true);
      }

      if (key == "ALLOW_FREE_OFFERS" && value == "ENABLED") {
        commit("setAllowFreeOffers", true);
      }

      if (key == "KYC_PERSONAL_CHECKLIST" && (value == "true" || value == "1")) {
        commit("setKycPersonalChecklist", true);
      } else if(key == "KYC_PERSONAL_CHECKLIST" && !(value == "true" || value == "1")) {
        commit("setKycPersonalChecklist", false)
      }

      if (key == "ERROR_CREATE_CELCOIN" && value !== "" && value != null) {
        commit("addErrorCreateCelcoin", value);
      }

      if (key == "ERROR_KYC_CELCOIN" && value !== "" && value != null) {
        commit("addErrorKycCelcoin", value);
      }
    }
  },
  updateKycPersonalChecklist({commit}, data) {
    commit('setKycPersonalChecklist', data)
  },
  updateAllowFreeOffers({commit}, data) {
    commit('setAllowFreeOffers', data)
  },
  async fetchCategories({ commit }) {
    // this.categories = [
    //     {
    //         value: null,
    //         text: this.$t("views.seller.edit_product.text_2374"),
    //         disabled: true,
    //     },
    // ];

    await serviceCategory.search().then((res) => {
      res = res.filter(x => x.name !== 'Infoproduto' && x.name !== 'Cursos')
      commit(
        "setCategories",
        res.map((item) => ({ value: item.id, text: item.name }))
      );
    });
  },
  async fetchOffers({ commit, getters, state, dispatch }, data) {
    let product = Object.assign({},getters.product);
    const eventTickets = () => {
      if(getters.getFlags.includes("feature_tickets") && product.product_type_id == 3) return true;
      return false
    }
    if(eventTickets()){
      const findOffer = state.offers.find((x) => x.default === 1);
      if(findOffer?.id === product?.id && getters?.offers?.length) return
    }

    if(getters.getNeedOfferLoading){
      commit("setIsLoading", true);
    }

    await serviceProduct
      .read({ id: getters.productId + "/offers" })
      .then(async(response) => {
        await commit("setOffers", response);
        await commit("filterDefaultOffer", response);
        await commit(
          "setBaseAmount",
          response.filter((item) => item.default).pop()?.amount || 0
        );
        if(eventTickets()){
          await dispatch("fetchCurrency", data)
        }
      })
      .finally(() => {
        commit("setIsLoading", false);
      });
  },
  async fetchCurrency({ commit, getters }, data) {
    const countryCode = !getters.getIsHeaven ? 1 : 2;
    await serviceCurrency
    .read({ id: '/payment_methods/' + countryCode })
    .then(async(response) => {
      await commit(
        "setMethodsPayment",
        { response, this:data, getters }
      );
      await commit('setCurrencyMethodPayment')
    })
    .catch(err => console.error(err))
  },
  async fetchIntegrations(_, payload) {
    return await serviceIntegration.search(payload);
  },
  /**
   * @returns {void}
   * @description - This method updates the product.
   */
  async updateProduct({ getters, dispatch }) {
    let product = Object.assign({},getters.product);

    const eventTickets = () => {
      if(getters.getFlags.includes("feature_tickets") && product.product_type_id == 3) return true;
      return false
    }

    if (eventTickets()) {
      if(!getters.getEventFildsResult) {
        return
      }
      const currencyPayment = [...getters.getCurrencyPayment];
      product.method = currencyPayment.map(item => item.value).join(",");
      if(product.start_time.length === 5) product.start_time += ':00'
      if(product?.end_time?.length === 5) product.end_time += ':00'
      const defaultOffer = Object.assign({}, getters.defaultOffer);
      delete defaultOffer.hash;
      product.defaultOffer = defaultOffer;
    }

    if(!eventTickets() && product.product_type_id == 3){
      delete product.address;
    }

    dispatch("setLoading", true);
    return await serviceProduct.update(product).finally(() => {
      dispatch("fetchProduct", getters.productId);
    });
  },
  /**
   * @return {void}
   * @param {boolean} - value
   * @description - This method set isLoading with true or false.
   */
  setLoading({ commit }, value = false) {
    commit("setIsLoading", value);
  },
  setCustomCheckout({ commit }, payload) {
    if (payload.length) {
      commit("setCustomCheckout", payload);
    }
  },

  setImages({ commit }, payload) {
    commit("setImages", payload);
  },
  async fetchProductStatus({ state, commit, dispatch }, id) {
    if (!id || !state.fetchingStatus) return;

    const data = {
      id: `check-status-approval/${id}`,
    };

    const res = await serviceProduct.read(data);

    if (res?.status === "REQUESTED") {
      const timeout = setTimeout(() => {
        dispatch("fetchProductStatus", id);
      }, res.next_check_interval);
      commit("setTimeoutId", timeout);
    }
    commit("setProductStatus", res.status);
  },
  clearTimeoutId({ commit }) {
    commit("setFetchingStatus", false);
    commit("setTimeoutId", null);
  },
  updateProductStartDate({ commit }, payload) { commit("setProductStartDate", payload) },
  updateProductStartTime({ commit }, payload) { commit("setProductStartTime", payload) },
  updateProductEndDate({ commit }, payload) { commit("setProductEndDate", payload) },
  updateProductEndTime({ commit }, payload) { commit("setProductEndTime", payload) },
  updateProductZipCode({ commit }, payload) { commit("setProductZipCode", payload) },
  updateProductNumber({ commit }, payload) { commit("setProductNumber", payload) },
  updateProductStreet({ commit }, payload) { commit("setProductStreet", payload) },
  updateProductNeighborhood({ commit }, payload) { commit("setProductNeighborhood", payload) },
  updateProductCity({ commit }, payload) { commit("setProductCity", payload) },
  updateProductState({ commit }, payload) { commit("setProductState", payload) },
  updateProductComplement({ commit }, payload) { commit("setProductComplement", payload) },
  updateProductLocation({ commit }, payload) { commit("setProductLocation", payload) },
  updateProductAreNonTransferable({ commit }, payload) { commit("setProductAreNonTransferable", payload) },
  updateEnableInvoiceItems: ({commit}, data) => commit('setEnableInvoiceItems', data),
  updateItemsForInvoice: ({commit}, data) => commit('setItemsForInvoice', data),
  // Content
  async fetchWebhooks({ commit }, data){
    let query = Object.assign({}, data);
    delete query.this;
    await serviceCallback.search(query)
      .then(async(response) => {
        await commit("setWebhooks", { response, this:data.this });
      })
      .catch((err) => {
        return err;
      });
  }
};
