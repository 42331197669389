import router from "@/router/index";

import UserLoggedService from "@/services/resources/UserLoggedService";
import ClubService from "@/services/resources/ClubService";
import SubUserService from "@/services/resources/SubUsersService";
import RequestAccess from '@/services/resources/RequestAccessService.js'
import GenerateHash from '@/services/resources/GenerateHashService.js'
import RecoveryHash from '@/services/resources/RecoveryHashService.js'
import UserSettingsService from '@/services/resources/UserSettingsService'
import SubUserLoggedService from '@/services/resources/SubUserLoggedService';

const serviceSubUser = SubUserService.build();
const service = UserLoggedService.build();
const serviceRequestAccess = RequestAccess.build()
const serviceGenerateHash = GenerateHash.build()
const serviceRecoveryHash = RecoveryHash.build()
const serviceUserSettings = UserSettingsService.build()
const serviceSubUserLoggedService = SubUserLoggedService.build();

export default {
   userRequest: ({ commit, dispatch }, data) => {
    return new Promise(async(resolve, reject) => {
      service
        .get("")
        .then(async (resp) => {
          if(data === 'dashboard'){
            resp.dashboard = true
          }
          commit("userSuccess", resp);
          //dispatch('redirectUserToHome')
          window.localStorage.id_user_greenn = resp.id
          await dispatch('fetchUserSettings',resp.id);

          serviceUserSettings
            .search({ url: resp.id, filter: ['INDICATION'] })
            .then(res => {
              res.forEach((item) => {
                if (item.key == "INDICATION" && item.value === 'ENABLED') {
                  commit("setIndication", true)
                }
              })
            })
            .catch(err => console.error(err))

          resolve(resp);
        })
        .catch((err) => {
          console.error(err)
          // commit("userError");
          reject(err);
          // if resp is unauthorized, logout, to
          // dispatch('authLogout')
        });
    });
  },
  userRequestLogged: ({ commit }) => {
    return new Promise(async(resolve, reject) => {
      serviceSubUserLoggedService
        .get("")
        .then(async (resp) => {
          resolve(resp);
          commit("userLogged", resp);
        })
        .catch((err) => {
          console.error(err)
          reject(err);
        });
    });
  },
  getPanels: ({ dispatch }) => {
    return new Promise((resolve, reject) => {
      serviceSubUser
        .get("/panels")
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          console.error(err)
          reject(err);
        });
    });
  },
  setPanel: (context, payload) => {
    context.commit("setPanel", payload);
  },
  resetPanel: (context, payload) => {
    context.commit("resetPanel");
  },
  emailVerification: ({ commit, dispatch, state }, type) => {
    return true
  },
  clubVerification: ({ commit, dispatch }) => {
    const serviceClub = ClubService.build();
    return new Promise((resolve, reject) => {
      serviceClub
        .get("/check-has-integration")
        .then((resp) => {
          commit("userClub", resp);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  clubLogin: ({ commit, dispatch }) => {
    const serviceClub = ClubService.build();
    return new Promise((resolve, reject) => {
      serviceClub
        .get("/login")
        .then((resp) => {
          let routeData =
            process.env.VUE_CLUB_API_HOST + "/?rdr=" + resp.string;
          window.open(routeData, "_blank");
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  adminUserRequest: ({ commit, dispatch }) => {
    service
      .get("")
      .then((resp) => {
        // commit('adminUserSuccess', resp);
        commit("userSuccess", resp);
        dispatch("redirectUserToHome");
      })
      .catch((err) => {
        console.error(err)
        // commit("userError");
        // if resp is unauthorized, logout, to
        // dispatch('authLogout')
      });
  },
  redirectUserToHome: (context) => {
    router.push("/dashboard");
  },
  redirectUserVerification: (context) => {
    if (router.history.current.path != "/verificar-email") {
      router.push(`/verificar-email${window.location.search}`).catch(() => {});
    }
  },
  solicitationGreennHeaven({commit}, {system, userId, user }) {
    return new Promise((resolve, reject) => {
      serviceRequestAccess.update({system}, userId)
        .then(res => {
          commit('userSuccess', user)
          resolve(res)
        })
        .catch(err => reject(err))
    })
  },
  generateHash({commit}) {
    return new Promise((resolve, reject) => {
      serviceGenerateHash.search()
        .then(res => {
          commit('setHash', res.ck)
          resolve(res)
        })
        .catch(err => reject(err))
    })
  },
  recoveryHash({commit}, data) {
    return new Promise((resolve, reject) => {
      serviceRecoveryHash.create(data)
        .then(res => {
          commit('setHash', res.token)
          resolve(res)
        })
        .catch(err => reject(err))
    })
  },
  async fetchUserSettings({ commit }, id) {
    if(id){
      await serviceUserSettings.search({ url: id, filter: ['CHARGEBACK_EMAIL'] })
      .then(res => {
        commit("setCustomEmail", res);
      })
      .catch(err => console.error(err))
      .finally(() => {})
    }
  },
};
