<template>
  <b-modal
    id="solicitation-greenn"
    name="solicitation-greenn"
    size="md"
    :title="$t('views.seller.login.text_1568')"
  >
    <b-row>
      <b-col v-if="requestGreenn === 0" cols="12" class="mb-3">
        {{ `${$t('views.seller.login.text_1569')} ${!isHeaven ? 'Heaven' : 'Greenn'}, ${$t('views.seller.login.text_1576')}` }}
      </b-col>
      <b-col v-else cols="12" class="mb-3">
        {{ $t('views.seller.login.text_1570') }}
      </b-col>
    </b-row>

    <template v-slot:modal-footer="{ cancel }">
      <div class="d-flex justify-content-end">
        <div>
          <BaseButton v-if="requestGreenn === 0" variant="info" @click="onSubmit">
            {{ $t('views.seller.login.text_1572') }}
          </BaseButton>
          <BaseButton v-else variant="info" @click="cancel">
            OK
          </BaseButton>
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>

export default {
  name: 'ModalGreenn',
  computed: {
    isHeaven() {
      return this.$store.getters["getIsHeaven"]
    },
    userId() {
      return this.$store.getters.getProfile.id;
    },
    requestGreenn() {
      return this.$store.getters.getProfile.request_greenn;
    },
  },
  methods: {
    onSubmit() {
      let user = Object.assign({}, this.$store.getters.getProfile)
      user.request_greenn = 1
      this.$store.dispatch('solicitationGreennHeaven', {system: 'greenn', userId: this.userId, user})
        .then(res => {
          if(res) {
            this.$bvToast.toast(this.$t('views.seller.login.text_1573'), {
              title: this.$t('views.seller.login.text_1574'),
              variant: 'success',
              autoHideDelay: 5000,
              appendToast: true
            })
          }
        })
        .catch(err => {
          console.error(err)
          this.$bvToast.toast(this.$t('views.seller.login.text_1575'), {
              title: this.$t('views.seller.login.text_1574'),
              variant: 'danger',
              autoHideDelay: 5000,
              appendToast: true
            })
        })
      this.$bvModal.hide("solicitation-greenn")
    }
  }
}
</script>
